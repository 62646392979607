<template>
  <img :src="images[countryCode || defaultCountryCode]" :alt="$t.locationSelectorCurrentCountry" width="18" height="12">
</template>

<script lang="ts" setup>
import { filename } from 'pathe/utils'

defineProps<{ countryCode?: string }>()

const defaultCountryCode = useCountryCode()

const glob = import.meta.glob<string>('~/assets/flags/*.svg', { eager: true, import: 'default' })
const images = Object.fromEntries(Object.entries(glob).map(([key, value]) => [filename(key), value]))
</script>
